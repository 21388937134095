@import '../../../../styles/colors.scss';

.comments {
  border-top: 1px solid $LP-fgColor;

  .title-comments {
    padding: 1rem 0rem;
    margin-top: 1rem;
    font-size: 1.3rem;
    letter-spacing: 0.2rem;
  }
}
