@import 'styles/colors.scss';

.basic-switch {
  display: flex;
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    margin-left: 0.5rem;
    width: 70%;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: $LP-bgColor !important;

  }
  .p-inputswitch{
    // @media screen and (max-width: 770px) {
    //   width: 100%;
    // }
  }
  .p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none !important;
  }
}
