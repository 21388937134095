@import '../../../styles/colors.scss';

#depoimentos {
  width: 100%;
  margin-top: 4rem;

  &.container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9fb;
    padding: 5rem 0rem 5rem 0rem;

    .content {
      width: 100%;
      max-width: 1250px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: flex-start;

      padding: 1rem;

      gap: 0.5rem;

      @media screen and (max-width: 821px) {
        flex-flow: column nowrap;
        gap: 1rem;
        padding: 0 1rem;
      }

      .testimony-info {
        width: 100%;
        min-width: 15rem;

        .testimony-info-title {
          width: 100%;
          margin: 0;

          color: $LP-bgColor;

          font-weight: bold;
          font-family: 'Poppins-Bold';
        }
        .testimony-info-emphasis {
          width: 100%;
          margin: 0;
        }
        .testimony-info-button {
          width: 100%;
          height: 100%;

          padding: 4rem;

          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;

          .testimony-info-button-input {
            font-size: 0.8rem;
          }

          @media screen and (max-width: 821px) {
            display: none;
          }
        }
      }

      .testimony-carousel-area {
        width: 100%;
        max-width: 60%;

        .testimony-carousel {
          .p-carousel .p-carousel-item {
            padding: 1rem !important;
          }

          .testimony-carousel-item {
            width: 100%;
            height: 25rem;
            padding: 1rem 1rem 1rem 1rem;

            background-color: white;
            border: 1px solid $LP-mgColor;
            border-radius: 0.5rem;

            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            align-items: flex-start;

            gap: 0.5rem;

            font-family: 'Poppins-Regular';

            @media screen and (max-width: 821px) {
              height: auto;
              overflow-y: auto;
            }

            h1 {
              margin: 0;
              color: $LP-bgColor;
            }

            p {
              width: 100%;
              margin: 0;

              font-size: 0.8rem;
            }

            .testimony-carousel-item-author {
              display: flex;
              flex-flow: row nowrap;
              justify-content: flex-start;
              align-items: center;

              gap: 0.5rem;

              img {
                height: 4rem;
                width: 4rem;

                border-radius: 0.5rem;
              }
            }
          }

          .testimony-carousel-indicator {
            width: 0.8rem;
            height: 0.8rem;

            border-radius: 1rem;
          }

          .p-highlight {
            .testimony-carousel-indicator {
              background-color: $LP-mgColor;
            }
          }
        }

        @media screen and (max-width: 821px) {
          width: 100%;
          max-width: 100%;
        }
      }

      .testimony-see-more-button {
        width: 100%;

        display: none;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 821px) {
          display: flex;
        }
      }
    }
  }
}
