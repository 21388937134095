@import '../../../../styles/colors.scss';

.pannel-menu {
  height: calc(100vh - 7rem);
  width: 18rem;

  background-color: $LP-bgColor;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 1rem 0;

  row-gap: 2rem;

  @media only screen and (max-width: 940px) {
    width: 6rem;
  }

  .pannel-menu-new-post {
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: center;
    column-gap: 1rem;

    svg {
      font-size: 20px;
    }

    @media only screen and (max-width: 940px) {
      .p-button-label {
        display: none !important;
      }
    }

    &:hover {
      background-color: $LP-fgColor !important;
    }
    &:active {
      background-color: $LP-fgColor !important;
    }
  }

  .pannel-menu-area-line {
    background-color: $LP-mgColor;
    width: 90%;
    height: 0.06rem;
  }

  .pannel-menu-list {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .pannel-menu-item {
      height: 3rem;
      width: 100%;

      padding: 0 2rem;

      // border-bottom: 1px solid aqua;

      color: $LP-mgColor;
      font-weight: 700;

      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: flex-start;

      column-gap: 1rem;

      cursor: pointer;

      .pannel-menu-item-label {
        width: 100%;
      }

      @media only screen and (max-width: 940px) {
        .pannel-menu-item-label,
        .pannel-menu-item-selected-icon {
          display: none !important;
        }

        font-size: 1.5rem;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
      }

      &.active {
        color: white;
        // background-color: change-color($color: $LP-bgColor, $blue: 75);
      }

      &:hover {
        background-color: change-color($color: $LP-bgColor, $blue: 75);
      }
    }
  }
}
