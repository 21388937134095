@import '../../../styles/colors.scss';

#posts {
  &.container {
    height: 100%;
    padding: 1.5rem 2rem 2rem 2rem;
    background-color: #f9f9fb !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      max-width: 1250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f9f9fb !important;
      align-items: center;
      .title-posts {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        font-weight: bold;
        padding-bottom: 1rem;
        border-bottom: 3px solid $LP-fgColor;
        color: $LP-bgColor;
        width: 400px;
        word-wrap: break-word;
        text-align: center;
        @media screen and (max-width: 770px) {
          width: 320px;
        }
      }
    }
  }
}
