@import '../../../../styles/colors.scss';

.posts-similar {
  border-top: 1px solid $LP-fgColor;

  .title-similar {
    padding: 0.2rem 0rem;
    margin-top: 1rem;
    font-size: 1.3rem;
    letter-spacing: 0.2rem;
  }

  .col-container {
    display: flex;
    gap: 1rem;
    margin: 1rem 0rem;
    cursor: pointer;

    @media only screen and (max-width: 1250px) {
      justify-content: center;
    }

    @media only screen and (max-width: 850px) {
      flex-direction: column;
    }

    .col {
      flex-basis: 18rem;
      border-radius: 10px;
      box-shadow: 0px 0px 8px 2px rgba(99, 99, 99, 0.2);

      @media only screen and (max-width: 850px) {
        flex-basis: auto;
      }

      .image-container-similar {
        width: 100%;
        height: 170px;
        overflow: hidden;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        @media only screen and (max-width: 850px) {
          height: 250px;
        }

        @media only screen and (max-width: 600px) {
          height: 170px;
        }

        .image-similar {
          width: 100%;
          height: 100%;
          object-fit: cover;
          -webkit-transition: 0.2s ease-in-out;
          transition: 0.2s ease-in-out;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
      .description-container-similar {
        width: 100%;
        padding: 1rem 0rem 1rem 0rem;

        .title-similar {
          margin: 0rem 1rem;
          font-size: 1.1rem;
          letter-spacing: 0.1rem;
          color: $LP-bgColor;
        }
      }
    }
  }
}
