$LP-bgColor: rgba(2, 35, 99, 1);
$LP-bgColorWh: #f7f7f7;
$LP-mgColor: rgb(0, 103, 138, 1);
$LP-fgColor: rgba(254, 201, 1, 1);
$LP-fgOpColor: rgba(184, 170, 144, 0.9);
$LP-txtColor: rgba(4, 34, 59, 1);
$LP-txtColor-alternative: rgba(254, 201, 1, 1);
$LP-txtColor-secondary: #ffff;
$LP-txtColor-grey: #888888;
$LP-wpColor: #25d366;
$LP-iconColor: rgba(255, 255, 255, 1);

$LP-errorColor: #ed4337;
