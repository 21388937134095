@import '../../../styles/colors.scss';

#lead-contact {
  &.container-contact {
    width: 100%;

    .image {
      width: 100%;
      min-height: 32rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-image: url(../../../assets/images/background-call-to-action-contato.png);
      background-size: cover;
      background-repeat: no-repeat;

      @media screen and (max-width: 1250px) {
        background-image: url(../../../assets/images/background-call-to-action-contato2.png);
      }

      .content {
        width: 1250px;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @media screen and (max-width: 1250px) {
          width: 100%;
          padding: 1.5rem;
        }

        .title {
          color: #022363;
          font-weight: 900;
          font-size: 60px;
          line-height: 107.52%;
          color: #022363;
        }

        .subtitle {
          font-weight: 500;
          font-size: 40px;
          line-height: 48px;
          color: #227737;
        }

        .button {
          max-width: 20rem;
          padding: 1.5rem 0rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          background: #3fd364 !important;
          border-color: #3fd364 !important;
          border: 1px solid #3fd364 !important;
          border-radius: 6px;
          font-weight: 700;
          font-size: 25px;
          line-height: 46px;
        }
      }
    }
  }
}
