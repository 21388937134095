.skeleton-container {
  margin-top: 3rem;

  .emphasis {
    border-radius: 10px;
    width: 1250px !important;
    height: 300px !important;
  }

  .container-card {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem;
    gap: 3.9rem;

    @media only screen and (max-width: 1250px) {
      justify-content: center;
    }

    .card {
      width: 375px !important;
      height: 510px !important;
      border-radius: 10px;
    }
  }
}
