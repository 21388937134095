@import '../../../styles/colors.scss';

#sobre-nos {
  &.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);

    .content {
      max-width: 1250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 0 2rem 0rem 2rem;


      .descriptions {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;

        font-family: "Poppins-Light";

        .office-description {
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;


          padding: 0rem 3rem 3rem 3rem;

          .title {
            font-size: 4.5rem;
            font-weight: bold;
            width: 100%;

            margin: 0;
            color: $LP-mgColor;
            font-family: "Poppins-Bold";
          }

          p {
            width: 100%;
            font-size: 1rem;
            margin: 0;
            text-align: justify;

            &.office-description-emphasis{
              font-weight: bold;
              font-size: 1.2rem;

            }
          }

          .office-description-emphasis-video{
            width: 100%;
          }

          .about-us-button{
            margin-top: 1rem;
          }

        }

        @media screen and (max-width: 940px) {
          .office-description{
            align-items: center;
            .title {
              padding: 0;
              font-size: 3rem;

            }
            p {
              &.office-description-emphasis{
                font-weight: bold;
                font-size: 1rem;
              }
            }
          }
        }

        .office-impact-video{
          iframe  {
            width: 30rem;
            height: 16.88rem;
          }
        }

        @media screen and (max-width: 940px) {
          flex-direction: column !important;
          .office-description {
            padding: .5rem 0rem 3rem 0rem;
          }
          .office-impact-image{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 100%;
              height: 'auto';
              object-fit: cover;
            }
          }
        }
      }

    }
  }
}
