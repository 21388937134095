@import '../../../styles/colors.scss';

.blog-post-container {
  max-width: 100vw;
  width: 100%;
  min-height: calc(100vh - 25.2rem);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 2rem 2rem 2rem;

  @media only screen and (max-width: 950px) {
    padding: 0rem !important;
    margin-bottom: 1.5rem;
  }

  .container {
    max-width: 1250px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .page-title {
      width: 15rem;
      text-align: center;
      font-size: 3rem;
      font-weight: bold;
      padding-bottom: 1rem;
      margin-top: 2rem;
      border-bottom: 3px solid $LP-fgColor;
      color: $LP-bgColor;
    }

    .body {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 2rem;

      .first-container {
        width: 75%;
        padding: 0rem 1rem;

        &.width100 {
          width: 75% !important;
          @media only screen and (max-width: 1250px) {
            width: 100% !important;
          }
        }

        @media only screen and (max-width: 1250px) {
          width: 100%;
        }

        .first-content {
          max-width: 100%;
          padding: 2rem;
          border-radius: 10px;
          box-shadow: 0px 0px 8px 2px rgba(99, 99, 99, 0.2);
        }
      }

      .second-container {
        width: 25%;

        @media only screen and (max-width: 1250px) {
          display: none !important;
        }
      }
    }
  }
}
