@import '../../../../styles/colors.scss';

.posts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 3.9rem;
  margin-top: 3.5rem;
  margin-bottom: 2rem;
  cursor: pointer;

  @media only screen and (max-width: 1250px) {
    justify-content: center;
  }

  .posts-content {
    width: 375px;
    box-shadow: 0px 0px 8px 2px rgba(99, 99, 99, 0.2);
    border-radius: 10px;

    @media only screen and (max-width: 880px) {
      width: 90vw;
    }

    .image-container {
      width: 100%;
      height: 220px;
      overflow: hidden;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      @media only screen and (max-width: 880px) {
        min-height: 350px;
      }

      @media only screen and (max-width: 650px) {
        min-height: 220px;
      }

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        -webkit-transition: 0.2s ease-in-out;
        transition: 0.2s ease-in-out;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .description-container {
      width: 100%;
      height: 235px;
      color: $LP-bgColor;

      @media only screen and (max-width: 650px) {
        height: 100%;
        margin-bottom: 1rem;
      }

      .line-clamp {
        -webkit-line-clamp: 9;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden;

        .title {
          padding: 1rem 1rem;
          font-size: 1.1rem;
          letter-spacing: 0.1rem;
        }

        .date {
          font-weight: bold;
          opacity: 0.6;
          margin: 0.2rem 1rem;
        }

        .description {
          margin: 1rem 1rem 0rem 1rem;
        }
      }
    }
  }
}
