.post-header {
  .input-title {
    span {
      input {
        background-color: white !important;
        color: black !important;
        border: 1px solid #ccc !important;
      }
    }
  }

  .container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
    @media only screen and (max-width: 1250px) {
      flex-direction: column;
    }

    .content-title {
      font-size: 1.8rem;
      letter-spacing: 0.3rem;
    }

    .content-button {
      display: flex;
      gap: 1rem;

      .preview,
      .cancel {
        i {
          margin-top: 0.2rem;
        }
        span {
          @media only screen and (max-width: 580px) {
            display: none;
          }
        }
      }

      .save,
      .edit {
        span {
          @media only screen and (max-width: 580px) {
            display: none;
          }
        }
      }

      .cancel {
        background-color: #ef4444 !important;
        border: 1px solid #ef4444 !important;

        &:focus {
          box-shadow: 0 0 0 0.15rem #ef4444 !important;
        }
      }

      .save {
        background-color: #22c55e !important;
        border: 1px solid #22c55e !important;

        &:focus {
          box-shadow: 0 0 0 0.15rem #22c55e !important;
        }
      }

      .edit {
        background-color: #f59e0b !important;
        border: 1px solid #f59e0b !important;

        &:focus {
          box-shadow: 0 0 0 0.15rem #f59e0b !important;
        }
      }
    }
  }

  .title {
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
  }

  .date {
    padding: 1rem 0rem;
    font-weight: bold;
    opacity: 0.6;
  }
}

.confirm-accept {
  background-color: #f59e0b !important;
  border: 1px solid #f59e0b !important;
  &:focus {
    box-shadow: 0 0 0 0.15rem #f59e0b !important;
  }
}

.confirm-edit {
  background-color: #22c55e !important;
  border: 1px solid #22c55e !important;
  &:focus {
    box-shadow: 0 0 0 0.15rem #22c55e !important;
  }
}

.confirm-reject {
  background-color: #ef4444 !important;
  border: 1px solid #ef4444 !important;
  &:focus {
    box-shadow: 0 0 0 0.15rem #ef4444 !important;
  }
}
