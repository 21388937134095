@import '../../../../styles/colors.scss';

.posts-content-recent {
  width: 300px;
  box-shadow: 0px 0px 8px 2px rgba(99, 99, 99, 0.2);
  margin-bottom: 2rem;
  border-radius: 10px;
  cursor: pointer;

  .image-container {
    width: 100%;
    height: 180px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .description-container {
    width: 100%;
    min-height: 40px;

    .title {
      padding: 1rem 1rem;
      font-size: 1.1rem;
      letter-spacing: 0.1rem;
      color: $LP-bgColor;
    }
  }
}
