@import '../../../../styles/colors.scss';

#footer {
  &.container {
    .background-image {
      background-color: $LP-bgColor;
    }

    .footer-newsletter{
      width: 100%;

      color: white;
      background-color: $LP-bgColor;

      padding: 2rem 0;

      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      .footer-newsletter-title{
        text-align: center;
      }

      .footer-newsletter-form{
        width: 100%;
        max-width: 1250px;

        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-start;

        gap: 1rem;

        @media screen and (max-width: 400px) {
          flex-direction: column;
          align-items: center;
          padding: 1rem;
        }

        .footer-newsletter-form-input{
          max-width: 50%;
          .p-inputtext{
            border-radius: 10rem !important;
          }

          @media screen and (max-width: 400px) {
           width: 100%;
           max-width: 100%;
          }
        }

        .footer-newsletter-form-button{
          border-radius: 10rem !important;
        }

      }

      .footer-newsletter-line{
        width: 85%;
        max-width: 1250px;
        border-bottom: 1px solid white;
        padding: 2rem 0;
      }
    }

    .content {
      min-height: 15rem;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 6rem;
      justify-content: center;
      @media only screen and (max-width: 800px) {
        height: 100%;
      }
      .navigation-footer {

        display: flex;
        justify-content: space-between;
        color: white;
        width: 100%;
        max-width: 1250px;
        padding: 0rem 6rem;

        @media only screen and (max-width: 800px) {
          height: 100%;
          align-items: center;
          flex-direction: column;
          gap: 3rem;
          padding: 3rem;
        }
        .social-footer {
          display: flex;
          flex-direction: column;
          gap: .1rem;

          .social-footer-impact-message{
            padding: 0 1rem;
            margin: 0;
          }

          @media only screen and (max-width: 800px) {
            align-items: center;
            .social-footer-impact-message{
              text-align: center;
            }
          }
        }
        .menu-footer {
          display: flex;
          flex-direction: column;
          @media only screen and (max-width: 800px) {
            align-items: center;
          }
          .menu-footer-title {
            font-weight: bold;
            font-size: 1.5rem;
            padding-bottom: 1rem;
          }
          .menu-footer-links {
            display: flex;
            flex-direction: column;
            @media only screen and (max-width: 800px) {
              align-items: center;
              gap: 1rem;
            }
            a {
              text-decoration: none;
              color: white;
              cursor: pointer;
              &:hover {
                color: $LP-mgColor;
              }
            }
          }
        }
        .contact-footer {
          display: flex;
          flex-direction: column;
          align-items: center;

          @media only screen and (max-width: 800px) {
            align-items: center;
          }
          .contact-footer-title {
            font-weight: bold;
            font-size: 1.5rem;
            padding-bottom: 1rem;
          }
          .contact-footer-social-media{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-evenly;
            gap: 1rem;

            svg{
              font-size: 1.5rem;
              cursor: pointer;
            }
          }

          .contact-footer-descriptions {
            display: flex;
            flex-direction: column;
            gap: .5rem;

            p{
              margin: 0
            }

            a {
              text-decoration: none !important;
              color: $LP-iconColor;
            }

            @media only screen and (max-width: 800px) {
              align-items: center;
              text-align: center;
            }
          }
          .contact-footer-whatsapp{
            font-size: 1.2rem;
            text-align: center;

            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            span{
              font-size: 1.5rem;

              i{
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
   .container-footer{
    display: flex;
    justify-content: center;
    min-height: 3.5rem;
    .powered-footer {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0.5rem;
      background-color: white;
      border-top-left-radius: .5rem;
      border-top-right-radius: .5rem;
      color: black;
      gap: 1rem;
      position: relative;
      width: 1250px;
      top: 0px;
      .rights-footer {
        display: flex;
        gap: 1rem;
        @media only screen and (max-width: 800px) {
          align-items: center;
          flex-direction: column;
          gap: 0.2rem;
        }
      }
      .programming-footer {
        a {
          text-decoration: none;
          color: #6fccf9;
          font-weight: bold;
          cursor: pointer;
        }
      }
      @media only screen and (max-width: 800px) {
        align-items: center;
        flex-direction: column;
        overflow-wrap: break-word;
      }
    }
   }
  }
}
