.image-container-emphasis {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  aspect-ratio: 100 / 29;

  .html-image {
    width: 100%;

    .image {
      width: 100%;
      max-height: 25rem;
      object-fit: cover;
      border-radius: 10px;
      cursor: pointer;
    }
  }

  .image {
    width: 100%;
    max-height: 40rem;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;

    &.not-cursor {
      cursor: auto;
    }
  }
}
