@import 'styles/colors.scss';

.card-courses {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 1rem;
  transition: 1s;

  .card-courses-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 35rem;
    background-color: $LP-bgColorWh;

    .card-courses-image{
      width: 100%;
      height: 15rem;
      img{
        width: 100%;
        height: 256px;
        border-radius: 1rem;
        object-fit: cover;
        @media screen and (max-width: 770px) {
          width: 100%;
          height: 100%;
        }
      }
    }
    .card-courses-decriptions {
      height: 20rem;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      gap: 1rem;
      padding: 3rem 2rem 2rem 2rem;
      border-radius: 0 0 1rem 1rem;

      .logo-card{
        position: absolute;
        bottom: 5rem;
        right: 2rem;
        width: 6rem;
      }
      .card-courses-description {
        color: $LP-txtColor-grey;
        font-size: 0.8rem;
        font-weight: bold;
      }
      .card-courses-description-title {
        display: flex;
        position: fixed;
        top: 13rem;
        .title{
          font-weight: bolder;
          min-width: 14rem;
          max-width: 20rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: $LP-txtColor-secondary;
          font-size: 1.5rem;
          background-color: $LP-txtColor-alternative;
          border-radius: 1rem;
          padding: 0.5rem 1rem;
        }
      }
      .card-courses-description-link {
        color: $LP-bgColor;
        font-weight: bold;
        position: fixed;
        bottom: 5rem;
        width: 100%;
      }
    }
    @media screen and (max-width: 770px) {
      flex-direction: column;
      padding: 0;
      text-align: justify;
      .card-courses-decriptions {
        .card-courses-description-title {
          .title{
            font-size: 1rem;
            max-width: 15rem;
          }
        }
        .card-courses-description {
          font-size: 0.7rem;
        }
        .card-courses-description-link {
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media screen and (max-width: 770px) {
    margin: 0 1rem;
  }
}
