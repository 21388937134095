@import '../../styles/colors.scss';

.signin {
  width: 100vw;
  height: 100vh;
  background-image: url('https://img.freepik.com/fotos-gratis/conceito-de-homens-de-negocios-de-aperto-de-mao_53876-31214.jpg?w=1380&t=st=1687095944~exp=1687096544~hmac=625eda7bddbf5c41bffe91bdd65dca55a501d79eb636dbcf7785685994a70971');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .signin-area {
    height: 100vh;
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.842);

    .signin-area-login {
      width: 25rem;
      height: 30rem;

      padding: 1rem;
      margin: 1rem;

      background-color: change-color($color: $LP-bgColor, $alpha: 0.6);

      border-radius: 1rem;

      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      row-gap: 1rem;

      .signin-area-login-logo {
        height: 8rem;

        user-select: none;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -ms-user-select: none;
      }

      .signin-area-login-line {
        background-color: change-color($color: $LP-mgColor, $alpha: 0.6);
        width: 20rem;
        height: 0.06rem;
      }

      h3 {
        color: $LP-mgColor;
        margin: 1rem 0;
      }

      .custom-button {
        &:hover {
          color: $LP-txtColor-alternative !important;
        }
      }
    }
  }
}
