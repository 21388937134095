@import './colors.scss';

.p-button {
  color: $LP-txtColor-alternative !important;
  background-color: $LP-bgColor !important;
  border: 1px solid $LP-bgColor !important;
  &:focus {
    box-shadow: 0 0 0 0.15rem rgba($color: $LP-bgColor, $alpha: 0.5) !important;
  }
  &.p-button-outlined {
    background-color: transparent !important;
    color: $LP-bgColor !important;
  }
  &.p-button-text {
    background-color: transparent !important;
    color: $LP-bgColor !important;
    border-color: transparent !important;
  }
}

.p-button.p-button-secondary {
  color: $LP-txtColor-secondary !important;
  background-color: $LP-mgColor !important;
  border: 1px solid $LP-mgColor !important;
  &.p-button-outlined {
    background-color: transparent !important;
    color: $LP-mgColor !important;
  }
  &.p-button-text {
    background-color: transparent !important;
    color: $LP-mgColor !important;
    border-color: transparent !important;
  }
  &:hover {
    background-color: $LP-mgColor !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.15rem rgba($color: $LP-mgColor, $alpha: 0.5) !important;
  }
}

.p-button.p-button-success {
  color: $LP-iconColor !important;
  background-color: #3FD364 !important;
  border: 1px solid #3FD364 !important;
  &.p-button-outlined {
    background-color: transparent !important;
    color: #3FD364 !important;
  }
  &.p-button-text {
    background-color: transparent !important;
    color: $LP-iconColor !important;
    border-color: transparent !important;
  }
  &:hover {
    background-color: #3FD364 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.15rem rgba($color: #3FD364, $alpha: 0.5) !important;
  }
}

.p-button.p-button-info {
  color: $LP-txtColor-secondary !important;
  background-color: $LP-bgColor !important;
  border-color: $LP-bgColor !important;
  border: 1px solid $LP-bgColor !important;
  &:focus {
    box-shadow: 0 0 0 0.15rem rgba($color: $LP-bgColor, $alpha: 0.5) !important;
  }
  &.p-button-outlined {
    background-color: transparent !important;
    color: $LP-fgColor !important;
  }
  &.p-button-text {
    background-color: transparent !important;
    color: $LP-fgColor !important;
    border-color: transparent !important;
  }
}

.p-button.p-button-warning {
  color: $LP-bgColor !important;
  background-color: $LP-fgColor !important;
  border-color: $LP-fgColor !important;
  border: 1px solid $LP-fgColor !important;
  &:focus {
    box-shadow: 0 0 0 0.15rem rgba($color: $LP-fgColor, $alpha: 0.5) !important;
  }
  &.p-button-outlined {
    background-color: transparent !important;
    color: $LP-txtColor-alternative !important;
  }
  &.p-button-text {
    background-color: transparent !important;
    color: $LP-txtColor-alternative !important;
    border-color: transparent !important;
  }
  &:hover{
    color: $LP-txtColor-alternative !important;
  }
}

