@import '../../../../styles/colors.scss';

.basic-dropdown {
  width: 100%;
  height: 2.5rem;
  span {
    width: 100%;
  }
  .icon {
    color: $LP-bgColor !important;
    &.cursor {
      cursor: pointer;
    }
    &.invalid-input {
      color: $LP-errorColor !important;
    }
  }

  .field {
    width: 100%;
    height: 2.5rem;
    font-size: 14px !important;
    display: flex;
    align-items: center;

    color: $LP-bgColor !important;
    background-color: white !important;

    border: 1px solid $LP-bgColor !important;

    span label {
      font-weight: 200 !important;
    }

    &:focus {
      box-shadow: 0 0 0 0.1rem rgba($color: $LP-bgColor, $alpha: 0.5) !important;
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: $LP-bgColor;
      transition: background-color 86400s ease-in-out 0s !important;
    }
  }

  .invalid {
    border: 1px solid $LP-errorColor !important;

    &:focus {
      box-shadow: 0 0 0 0.15rem rgba($color: $LP-errorColor, $alpha: 0.5) !important;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: $LP-errorColor;
    }
  }

  .p-float-label {
    label {
      top: 0.3rem;
      margin-top: 6px;
      padding: 0 4px 0 2px;
      background-color: $LP-txtColor-alternative;
    }
  }
}
