@import '../../../styles/colors.scss';

.section-bar{
  height: 10rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 0;

  &.home{
    background-color: white;
    position: relative;

    .section-bar-area{
      background-color: $LP-fgColor;
    }

    .section-bar-arrow{
      fill: aqua !important;
    }
  }

  &.aboutUs{
    height: 10rem;
    background-color: $LP-bgColor;

    .section-bar-area{
      background-color:  white;
    }
  }

  .section-bar-area{
    height: 7rem;
    width: 100%;
    z-index: 0;
  }

  .section-bar-arrow{
    width: 24rem;

    position: relative;
    top: -1px;

    z-index: 0;
  }
}
