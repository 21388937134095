@import '../../../../styles/colors.scss';

.list-search {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;

  @media only screen and (max-width: 500px) {
    justify-content: center;
  }

  .search-input {
    max-width: 15rem;
    width: 100%;
    .icon {
      color: $LP-bgColor !important;
    }
    .field {
      color: $LP-bgColor !important;
      background-color: white !important;
      border-top-right-radius: 0rem;
      border-bottom-right-radius: 0rem;
      border: 1px solid $LP-bgColor !important;
      &:focus {
        box-shadow: 0 0 0 0.1rem rgba($color: $LP-bgColor, $alpha: 0.5) !important;
      }
    }
  }
  .button-search {
    height: 2.5rem;
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
  }
}
