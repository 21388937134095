.skeleton-container-post {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;

  .first-container {
    width: 75%;
    padding: 0rem 1rem;
    height: 100rem;

    &.width100 {
      width: 75% !important;
      height: 100rem;
      @media only screen and (max-width: 1250px) {
        width: 100% !important;
      }
    }

    @media only screen and (max-width: 1250px) {
      width: 100%;
    }

    .emphasis {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .second-container {
    width: 25%;

    @media only screen and (max-width: 1250px) {
      display: none !important;
    }

    .card {
      width: 300px !important;
      height: 250px !important;
      margin-bottom: 1rem;
    }
  }
}
