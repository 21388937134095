@import '../../../styles/colors.scss';

.basic-input-errors {
  color: $LP-errorColor;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  p {
    font-family: -apple-system, 'Roboto', sans-serif;
    font-weight: 500 !important;
    margin: 5px 0px 0px 0px;
    user-select: none;
  }
}
