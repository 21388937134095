@import '../../styles/colors.scss';

.pannel-posts {
  width: 100%;
  min-height: 100%;

  padding: 1rem;

  background-color: #f9f9fb;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-center;

  row-gap: 1rem;

  .pannel-posts-searchbar {
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;

    .pannel-posts-searchbar-input {
      max-width: 15rem;
      width: 100%;
      .icon {
        color: $LP-bgColor !important;
      }
      .field {
        color: $LP-bgColor !important;
        background-color: white !important;
        border-top-right-radius: 0rem;
        border-bottom-right-radius: 0rem;
        border: 1px solid $LP-bgColor !important;
        &:focus {
          box-shadow: 0 0 0 0.1rem rgba($color: $LP-bgColor, $alpha: 0.5) !important;
        }
      }
    }
  }

  .button-search {
    height: 2.5rem;
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
  }

  &.pannel-posts-no-posts {
    justify-content: center;
  }

  .pannel-posts-no-posts-logo {
    font-weight: 700;
    color: $LP-bgColor;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
      margin-top: 1rem;
    }

    img {
      width: 55px;
    }
  }

  .pannel-posts-item {
    width: 100%;
    height: 8rem;

    padding: 0.5rem;

    background-color: white;

    border-radius: 0.5rem;

    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;

    gap: 1rem;

    box-shadow: 0px 0px 8px 2px rgba(99, 99, 99, 0.2);

    &:hover {
      cursor: pointer;
    }

    .pannel-posts-item-image {
      width: 12rem;
      height: 100%;

      .pannel-posts-item-image-skeleton {
        width: 12rem !important;
        height: 100% !important;
      }

      img {
        width: 12rem;
        height: 100%;

        object-fit: cover;
        aspect-ratio: 16/9;

        border-radius: 0.5rem;
      }
    }

    .pannel-posts-item-info {
      width: 100%;
      height: 100%;

      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;

      font-size: 12px;

      .pannel-posts-item-info-title {
        font-weight: 700;
        font-size: 1rem;
      }
    }

    .pannel-posts-item-actions {
      width: 20rem;
      height: 100%;

      padding: 1rem;

      color: $LP-bgColor;

      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
      justify-content: flex-end;
    }

    p {
      margin: 0;
      text-align: left;
      color: $LP-bgColor;
    }

    @media only screen and (max-width: 940px) {
      width: 100%;
      height: 100%;

      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: space-between;

      .pannel-posts-item-info {
        gap: 1rem;
      }

      .pannel-posts-item-image {
        width: 100%;
        height: 100%;

        .pannel-posts-item-image-skeleton {
          width: 100% !important;
          height: 10rem !important;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          aspect-ratio: 16/9;

          border-radius: 0.5rem;
        }
      }

      .pannel-posts-item-actions {
        width: 100%;
        padding: 0;
        align-items: flex-start;
        justify-content: center;
      }
    }
  }
}
