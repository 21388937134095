@import '../../../styles/colors.scss';

#lead-map {
  &.container-map {
    width: 1250px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem;

    @media screen and (max-width: 1250px) {
      width: 100%;
      flex-direction: column;
      margin-top: 3rem;
    }

    .image-container-emphasis {
      aspect-ratio: 100/29;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      .image {
        padding: 2rem;
        border-radius: 10px;
        cursor: pointer;
        max-height: 30rem;
        object-fit: cover;
        width: 100%;
      }
    }

    .container-text {
      width: 55%;
      min-height: 25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 2rem;
      padding: 2rem;

      @media screen and (max-width: 1250px) {
        width: 100%;
      }

      .title {
        font-weight: 900;
        font-size: 40px;
        line-height: 108.02%;
        text-align: center;
        color: #030f4b;
      }

      .subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 108.02%;
        text-align: center;
        color: #7b7b7b;
      }
    }
  }
}
