@import '../../../styles/colors.scss';

#contato {
  &.container {
    height: 100%;
    padding: 1.5rem 2rem 2rem 2rem;
    background-color: #f9f9fb;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.on-modal{
      padding: 0;
    }
    &.no-posts {
      background-color: rgba(255, 255, 255, 0.9) !important;
    }
    .content {
      max-width: 1250px;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .contact-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem 0;
        gap: 5rem;
        width: 100%;

        @media screen and (max-width: 770px) {
          flex-direction: column;
          gap: 1rem;
        }

        &.on-modal{
          padding: 0;
        }

        .contact-left{
          width: 50%;

          @media screen and (max-width: 770px) {
            width: 100%;
          }

          &.on-modal{
            width: 100%;
          }

          .title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 2.5rem;
            font-weight: bold;
            color: $LP-bgColor;
          }
          .description{
            color: $LP-txtColor-grey;
            font-size: 1.1rem;
            font-weight: bold;
            width: 320px;
            margin-bottom: 2rem;
          }
          .contact-form {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .custom-button-color {
              color: $LP-txtColor-secondary !important;
            }
            .contact-success-message {
              color: $LP-wpColor;
              font-size: 0.7rem;
            }
            .contact-error-message {
              color: $LP-errorColor;
              font-size: 0.7rem;
            }
          }
        }
        .contacts-image {
          width: 50%;
          height: 30rem;
          object-fit: cover;
          order: 1;
          @media screen and (max-width: 770px) {
            width: 100%;
          }
          img{
            width: 100%;
            height: 30rem;
            border-radius: 1rem;
            object-fit: cover;
          }
          @media screen and (max-width: 770px) {
            order: 0;
          }
          &.on-modal{
            display: none;
          }
        }
      }
    }
  }
}
