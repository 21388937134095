@import '../../../../styles/colors.scss';

.share {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
  border-top: 1px solid $LP-fgColor;

  .title-share {
    letter-spacing: 0.2rem;
    font-size: 1.3rem;
    letter-spacing: 0.2rem;
  }

  .icon-container {
    display: flex;

    .hex {
      width: 50px;
      height: 50px;
      background-color: $LP-bgColor;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 1rem 0rem 0rem;
      cursor: pointer;

      transition: transform ease 300ms;

      &:hover {
        transform: translate(0, -5px);
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white !important;
        font-size: 1.4rem;
      }
    }
  }
}
