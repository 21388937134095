@import '../../../../styles/colors.scss';

.header {
  position: relative;

  width: 100%;
  height: 7rem;

  background-color: $LP-bgColor;
  color: $LP-txtColor-alternative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  column-gap: 1rem;

  font-family: "Poppins-Light";

  &.header-mode-relative {
    position: relative;
    background-color: $LP-bgColor;
  }

  .header-logo-link{

    h2{
      color: $LP-txtColor-alternative;
      font-family: "Poppins-Medium";
    }

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    text-decoration: none;

    .header-logo {
      padding-left: 2rem;
      height: 100%;
      width: 6.5rem;

      user-select: none;
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -ms-user-select: none;

      @media only screen and (max-width: 1120px) {
        height: 4rem;
      }

      transition: 0.1s ease-out;
    }
  }

  .header-responsive-menu {
    padding-right: 2rem;

    .header-responsive-menu-area {
      z-index: 11;

      width: 100vw;
      height: 100vh;

      position: fixed;

      bottom: 0;
      right: 0;

      background-color: $LP-bgColor;

      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      row-gap: 1rem;

      .header-responsive-menu-area-button {
        width: 100vw;
        height: 4rem;
        margin-top: 4rem;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        padding-right: 4rem;
      }

      img {
        padding-left: 0;
        width: 6rem;
        height: 100%;
      }

      .header-responsive-menu-area-line {
        margin-top: 1.5rem;
        background-color: white;
        width: 20rem;
        height: 0.06rem;
      }

      ul {
        height: auto;
        list-style: none;
        padding-left: 0;
        font-family: "Poppins-Light";

        display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-end;
      gap: 1.2rem;

        li {
          color: $LP-iconColor;

          font-size: 1.5rem;
          font-weight: 700;
          font-smooth: always;
          text-align: center;

          transition: 1s;

          a {
            text-decoration: none;
            color: inherit;
          }
          &:hover {
            color: $LP-txtColor-alternative
          }
        }
      }
    }

    .header-responsive-menu-icon {
      display: flex;
      color: $LP-iconColor;
      font-size: 2rem;
      &:hover {
        cursor: pointer;
        color: $LP-mgColor;
      }
    }
  }

  .header-menu-list{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    ul {
    list-style: none;
    padding-right: 2rem;

    font-size: .9rem;

    li {
      color: $LP-iconColor;

      float: left;
      padding: 1rem;

      font-weight: 700;
      font-smooth: always;

      transition: 1s;

      a {
        text-decoration: none;
        color: inherit;
      }
      &:hover {
        color: $LP-txtColor-alternative;
      }
    }
  }
  }

  .header-menu-list-button{
    width: 10rem;
    font-size: .8rem;

    @media only screen and (max-width: 1120px) {
      height: 3rem;
      width: 12rem;

      font-size: 1rem;

    }
  }
}
