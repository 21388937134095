@import '../../../styles/colors.scss';

.blog-listing-container {
  max-width: 100vw;
  min-height: calc(100vh - 25.1rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 1.5rem 2rem 2rem 2rem;

  .content {
    max-width: 1250px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .page-title {
      width: 15rem;
      text-align: center;
      font-size: 3rem;
      font-weight: bold;
      padding-bottom: 1rem;
      margin-top: 2rem;
      border-bottom: 3px solid $LP-fgColor;
      color: $LP-bgColor;
    }

    .no-posts-container {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: calc(100vh - 35.5rem);

      .no-posts {
        font-weight: 700;
        color: $LP-bgColor;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        p {
          margin: 0;
          margin-top: 1rem;
        }

        img {
          width: 55px;
        }
      }
    }

    .button-load-more {
      margin-top: 1rem;
    }
  }
}
