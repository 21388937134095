@import '../../../styles/colors.scss';

#home {
  &.home-container {
    height: calc(100vh - 7rem);
    width: 100%;
    // margin-bottom: 1rem;

    .background-gradient{
      height: 100%;
      width: 100%;

      background: rgb(254,201,1);
      background: linear-gradient(125deg, rgba(254,201,1,0.5) 0%, rgba(254,201,1,0) 85%);
    }

    .background-image {
      height: 100%;
      width: 100%;

      background-image: url('../../../assets/images/home-fullhd.png');
      background-position: right top;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: transparent;
    }

    .home-content {
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      z-index: 1;

      .home-info{
        height: 100%;
        width: 100%;
        max-width: 1250px;

        padding: 4rem 0 0 4rem;

        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        row-gap: .5rem;

        h1{
          margin: 0;

          color: $LP-mgColor;
          font-family: "Poppins-Bold";
          font-size: 2.5rem;
          line-height: 2.5rem;
          text-shadow: 0px 0px 15px rgba(0,0,0,0.25);
          -webkit-text-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
          -moz-text-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
        }

        h2{
          margin: 0;

          color: $LP-mgColor;
          font-family: "Poppins-Bold";
          font-size: 2rem;
          line-height: 2rem;
          text-shadow: 0px 0px 15px rgba(0,0,0,0.25);
          -webkit-text-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
          -moz-text-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
        }

        @media only screen and (max-width: 1440px) {
          padding: 2rem;

          h1{
            font-size: 2rem;
            line-height: 2rem;
          }

          h2{
            font-size: 1.8rem;
            line-height: 1.8rem;
          }
        }

        @media only screen and (max-width: 1024px) {
          h1{
            font-size: 1.8rem;
            line-height: 1.8rem;
          }

          h2{
            font-size: 1.5rem;
            line-height: 1.5rem;
          }
        }

        @media only screen and (max-width: 768px) {
          h1{
            font-size: 1.6rem;
            line-height: 1.3rem;
          }

          h2{
            font-size: 1.3rem;
            line-height: 1.3rem;
          }
        }

        @media only screen and (max-width: 425px) {
          h1{
            font-size: 1.5rem;
            line-height: 1.5rem;
          }

          h2{
            font-size: 1.2rem;
            line-height: 1.2rem;
          }
        }

        .home-carousel{
          width: 100%;
          max-width: 1250px;

          p{
            width: 55%;
            margin: 0;

            font-size: 1rem;
            font-family: "Poppins-Light";

            @media only screen and (max-width: 1440px) {
              font-size: .9rem;
            }

            @media only screen and (max-width: 1024px) {
              font-size: .9rem;
            }

            @media only screen and (max-width: 768px) {
              width: 100% !important;
              font-size: .9rem;
            }

            @media only screen and (max-width: 425px) {
                font-size: .8rem;
            }
          }

          .home-carousel-indicators{
            padding: .2rem;

            @media only screen and (max-width: 425px) {
              padding: .5rem;
            }
          }

          .home-carousel-indicator{
            width: .8rem;
            height: .8rem;

            border-radius: 1rem;
          }

          .p-highlight{
            .home-carousel-indicator{
              background-color: $LP-mgColor;
            }
          }
        }

        .home-button{
          box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25) !important;
          -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
          -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);

          @media only screen and (max-height: 700px) {
            display: none;
          }
        }

      }

      .home-cards{
        width: 100%;
        max-width: 1250px;

        padding: 1rem 0 1rem 4rem;

        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        column-gap: 1rem;

        color: $LP-txtColor-alternative;

        z-index: 1;

        .home-card-body{
          width: 11rem;
          height: 11rem;

          padding: 1rem;

          background-color: white;
          border-radius: 2rem;

          font-family: "Poppins-Medium";

          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;

          row-gap: .2rem;

          -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
          -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
          box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);

          svg{
            font-size: 4.5rem;
          }

          .home-card-counter{
            font-size: 1.4rem;
            font-weight: bold;

            .p-carousel .p-carousel-indicators{
              padding: 0 !important;
            }
          }

          .home-card-title{
            font-size: .8rem;
          }

        }

        @media only screen and (max-width: 1440px) {
          padding: 0 0 1rem 2rem;

          .home-card-body{
            width: 10rem;
            height: 10rem;

            svg{
              font-size: 3.5rem;
            }

            .home-card-counter{
              font-size: 1.1rem;
            }

            .home-card-title{
              font-size: .7rem;
            }
          }
        }

        @media only screen and (max-height: 700px) {
          height: 2rem;
          margin-bottom: 1rem;

          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;

          gap: .5rem;

          .home-card-body{
            width: 9rem;
            height: 9rem;

            svg{
              font-size: 2.5rem;
            }

            .home-card-counter{
              font-size: 1rem;
            }

            .home-card-title{
              font-size: .6rem;
            }
          }
        }

        @media only screen and (max-width: 425px) {
          height: 2rem;
          margin-bottom: 1rem;

          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;

          gap: .5rem;

          .home-card-body{
            width: 9rem;
            height: 9rem;

            svg{
              font-size: 2.5rem;
            }

            .home-card-counter{
              font-size: 1rem;
            }

            .home-card-title{
              font-size: .6rem;
            }
          }
        }

        @media only screen and (max-width: 325px) {
          display: none;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      margin-bottom: 1.5rem;
      .background-image{
        background-size: 0%;
      }
    }
  }
}
