@import '../../../styles/colors.scss';

#gallery {
  &.gallery-container {
    width: 1250px;
    margin-top: 1rem;

    @media screen and (max-width: 1250px) {
      width: 100%;
      padding: 1.5rem;
    }

    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      color: #4e878c;
    }

    .subtitle {
      font-weight: 700;
      font-size: 36px;
      line-height: 47px;
      color: #333333;
    }

    .gallery {
      width: 1250px;
      display: flex;
      gap: 1rem;
      margin-top: 2rem;

      @media screen and (max-width: 1250px) {
        width: 100%;
      }

      @media screen and (max-width: 700px) {
        flex-direction: column;
      }

      .group {
        display: flex;
        width: 50%;
        gap: 1rem;

        @media screen and (max-width: 1250px) {
          flex-direction: column;
        }

        @media screen and (max-width: 700px) {
          width: 100%;
        }

        .column {
          width: 50%;
          height: 26rem;
          border-radius: 10px;

          @media screen and (max-width: 1250px) {
            width: 100%;
          }

          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
            border-radius: 10px;
          }
        }

        .column2 {
          width: 50%;
          min-height: 26rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          @media screen and (max-width: 1250px) {
            width: 100%;
          }

          .row1 {
            height: 12.5rem;

            @media screen and (max-width: 1250px) {
              height: 26rem;
            }

            .image {
              width: 100%;
              height: 100%;
              object-fit: cover;
              cursor: pointer;
              border-radius: 10px;
            }
          }

          .row2 {
            height: 12.5rem;

            @media screen and (max-width: 1250px) {
              height: 26rem;
            }

            .image {
              width: 100%;
              height: 100%;
              object-fit: cover;
              cursor: pointer;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
}
