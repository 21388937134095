@import '../../../../styles/colors.scss';

.card-course {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 1rem;
  transition: 1s;
  margin-top: 3rem;
  .card-course-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    @media screen and (max-width: 820px) {
      padding: 1rem 0;
    }
    .card-course-content-text{
      display: flex;
      .card-course-image{
        img{
          width: 484px;
          height: 350px;
          object-fit: cover;
          border-radius: 1rem;
          @media screen and (max-width: 820px) {
            width: 97%;
            height: 100%;
          }
        }
        &.second{
          order: 1;
          @media screen and (max-width: 820px) {
            order: 0;
          }
        }
      }
      .card-course-decriptions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding: 0 2rem 2rem 2rem;
        border-radius: 0 0 1rem 1rem;
        .card-course-description {
          color: $LP-txtColor-secondary;
          font-size: 1.2rem;
        }
        .card-course-description-title {
          color: $LP-txtColor-secondary;
          font-weight: bolder;
          text-align: flex-start;
          font-size: 3rem;
        }
        .card-course-description-link {
          color: $LP-txtColor-alternative;
          font-weight: bold;
        }
      }
      @media screen and (max-width: 820px) {
        flex-direction: column;
        padding: 0;
        text-align: justify;
        .card-course-decriptions {
          .card-course-description-title {
            font-size: 2rem;
          }
          .card-course-description {
            font-size: 1rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 820px) {
    margin: 0 0.5rem;
  }
}
