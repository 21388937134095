@import '../../../../styles/colors.scss';

.tags-container {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
  border-top: 1px solid $LP-fgColor;

  .title-tags {
    letter-spacing: 0.2rem;
    font-size: 1.3rem;
    letter-spacing: 0.2rem;
  }

  .card {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;

    .tag {
      padding: 0.5rem 1rem;
      margin: 0.2rem;
      background-color: $LP-bgColor;
      border-radius: 10px;
      color: white;
    }
  }
}
