@import '../../../styles/colors.scss';

.auth-loading {
  width: 100%;
  height: 100%;

  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  text-align: center;

  background-color: transparent;

  &.auth-loading-fullscreen {
    width: 100vw;
    height: 100vh;
  }

  .auth-loading-spinner {
    width: 6rem;
    height: 6rem;

    position: fixed;

    .p-progress-spinner-circle {
      stroke: $LP-mgColor !important;
    }
  }

  .auth-loading-logo {
    z-index: 12;
    width: 7rem;
    height: 7rem;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;

    background-color: transparent;
    border-radius: 100%;

    img {
      height: 3rem;
      -webkit-user-drag: none;
      animation: growing-image 2s 1;
      -webkit-animation: growing-image 2s 1;
    }
  }
}

@keyframes growing-image {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
