@import '../../../styles/colors.scss';

#cursos {
  &.container {
    height: 100%;
    padding: 1.5rem 2rem 2rem 2rem;
    background-color: $LP-bgColor !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 821px) {
      padding: 2rem 0.5rem;
    }
    .content {
      max-width: 1250px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $LP-bgColor !important;
      .title-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        .title {
          width: 100%;
          font-size: 3rem;
          font-weight: bold;
          text-align: center;
          color: $LP-txtColor-alternative;
          @media screen and (max-width: 821px) {
            padding: 0 1rem;
          }
        }
        .description{
          width: 100%;
          text-align: center;
          color:$LP-txtColor-secondary;
        }
        @media screen and (max-width: 821px) {
          width: 100%;
        }
      }
      .cards-courses{
        width: 100%;
      }
      @media screen and (max-width: 821px) {
        flex-direction: column;
      }
    }
  }
}
