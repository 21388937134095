@import '../../styles/colors.scss';

.pannel-contacts {
  width: 100%;
  min-height: 100%;

  padding: 1rem 0;

  overflow-y: auto;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  gap: 1rem;

  .pannel-contacts-header {
    width: 100%;

    padding: 0 1rem;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;

    .pannel-contacts-searchbar {
      width: 100%;

      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;

      .pannel-contacts-searchbar-input {
        max-width: 15rem;
        width: 100%;

        .icon {
          color: $LP-bgColor !important;
        }

        .field {
          color: $LP-bgColor !important;
          background-color: white !important;
          border-top-right-radius: 0rem;
          border-bottom-right-radius: 0rem;
          border: 1px solid $LP-bgColor !important;
          &:focus {
            box-shadow: 0 0 0 0.1rem rgba($color: $LP-bgColor, $alpha: 0.5) !important;
          }
        }
      }

      .pannel-contacts-search {
        height: 2.5rem;
        border-top-left-radius: 0rem !important;
        border-bottom-left-radius: 0rem !important;
      }
    }

    @media only screen and (max-width: 940px) {
      flex-flow: column-reverse nowrap;
      justify-content: flex-start;

      gap: 1rem;

      .pannel-contacts-searchbar {
        .pannel-contacts-search {
          font-size: 0.6rem;
          width: 6rem;
        }
      }
    }
  }

  .pannel-contacts-item-actions-delete-button {
    width: 2rem !important;
    height: 2rem !important;

    .basic-button svg {
      font-size: 2rem;
    }

    background-color: #ef4444 !important;
    border: 1px solid #ef4444 !important;

    &:hover {
      background-color: #b92719 !important;
      border: 1px solid #b92719 !important;
    }

    &:focus {
      box-shadow: 0 0 0 0.15rem #ef4444 !important;
    }
  }

  .accepted-news-position {
    .accepted-news-checked {
      color: green;
    }
    .accepted-news-unchecked {
      color: lightgray;
    }
  }

  .pannel-contacts-table-description {
    width: 100%;
    height: 100%;

    padding: 1rem;

    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;

    h5 {
      margin: 0;
    }

    .pannel-contacts-table-description-area {
      width: 100%;
      height: 100%;
      max-height: 10rem;

      padding: 1rem;

      box-shadow: 0px 0px 2px 1px rgba(99, 99, 99, 0.2);
      border-radius: 0.5rem;

      overflow-y: auto;

      scrollbar-width: auto;
      scrollbar-color: $LP-bgColor $LP-txtColor-alternative;

      text-align: justify;

      b {
        font-size: 1.2rem;
      }

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $LP-bgColor;
        border-radius: 12px;
        border: 2px solid $LP-txtColor-alternative;
      }
    }
  }

  .pannel-contacts-load-more {
    width: 100%;

    display: flex;
    justify-content: center;
  }
}

.pannel-contacts-export-dialog {
  width: 30vw;

  .p-dialog-title {
    color: $LP-bgColor !important;
  }

  .pannel-contacts-export-data {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    gap: 1.5rem;

    p {
      font-weight: bold;
      color: $LP-bgColor;

      margin: 0;

      text-align: center;

      span {
        font-size: 1.2rem;
        color: $LP-mgColor;
      }

      small {
        font-weight: lighter;
        text-align: center;
        margin: 0;
        font-size: 0.8rem;
      }
    }
  }

  @media only screen and (max-width: 940px) {
    width: 80vw !important;
  }
}

.pannel-contacts-export-button {
  flex-direction: row-reverse;
}

.pannel-contacts-confirm-dialog {
  .p-confirm-popup-content {
    padding: 0.6rem !important;
  }
  .pannel-contacts-confirm-dialog-message {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;

    gap: 1rem;

    svg {
      font-size: 3rem;
      color: $LP-bgColor;
    }

    p {
      font-weight: bold;
      color: $LP-bgColor;
    }

    p,
    small {
      margin: 0;
    }

    @media only screen and (max-width: 940px) {
      width: 60vw !important;
    }
  }

  .pannel-contacts-confirm-dialog-delete-button {
    background-color: #ef4444 !important;
    border: 1px solid #ef4444 !important;

    &:focus {
      box-shadow: 0 0 0 0.15rem #ef4444 !important;
    }
  }
}
