@import '../../../../styles/colors.scss';

.editor-article {
  padding: 1rem 0rem 0rem 0rem;
  line-height: 1.5rem;

  .editor {
    padding: 0rem;
  }

  .quill-editor {
    min-height: 15rem;
    margin-bottom: 1rem;
    .ql-container {
      min-height: 15rem;
      .ql-editor {
        min-height: 15rem;
      }
    }
  }

  p {
    img {
      object-fit: cover;
      max-width: 100%;
    }
  }
}

.speak-expert-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  margin-top: 1rem;

  .title {
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
  }
  .button {
    padding: 1rem;
    background: $LP-bgColor;
    display: flex;
    color: white;
    width: 15rem;
    border-radius: 10px;
    margin: 1rem 0rem 1rem 0rem;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
    transition: transform ease 300ms;

    &:hover {
      transform: translate(0, -5px);
    }
  }
}
