@import 'styles/fonts.scss';
@import 'styles/colors.scss';

body {
  margin: 0;
  font-family: 'Poppins-Light';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $LP-bgColor $LP-bgColor;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: $LP-bgColor;
  border-radius: 10px;
}

.no-decoration {
  text-decoration: none !important;
}

.p-fluid .p-chips {
  width: 100%;
}

.reveal {
  position: relative;
  transform: translateX(150px);
  opacity: 0;
  transition: 2s all ease-in-out;
}
.reveal.active {
  transform: translateX(0);
  opacity: 1;
}
