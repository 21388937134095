@import 'styles/colors.scss';

.list{
  width: 100%;
  margin-bottom: 1rem;
  .list-title{
    width: 100%;
    text-align: start;
    font-weight: bold;
    font-size: 3rem;
    color: $LP-bgColor;
  }
  .list-sub{
    background-color: $LP-bgColor;
    width: 8rem;
    height: 0.2rem;
  }
  .list-button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list-cards {
    width: 100%;
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    .swiper-horizontal {
      height: 38rem;
      width: 90vw;
      @media screen and (max-width: 770px) {
        height: 40rem;
      }
    }
    .swiper-slide {
      display: flex;
    }
    .swiper-pagination-bullet,
    .swiper-pagination-bullet-active {
      background: $LP-bgColorWh;
    }
    .swiper-button-next {
      color: $LP-bgColor;
      top: var(--swiper-navigation-top-offset, 28rem);
      right: var(--swiper-navigation-right-offset, -0.05rem);
      @media screen and (max-width: 770px) {
        right: var(--swiper-navigation-right-offset, 1rem);
      }
    }
    .swiper-button-prev {
      color: $LP-bgColor;
      top: var(--swiper-navigation-top-offset, 28rem);
      left: var(--swiper-navigation-left-offset, -0.05rem);
      @media screen and (max-width: 770px) {
        left: var(--swiper-navigation-left-offset, 1rem);
      }
    }
  }
}
