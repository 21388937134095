@import '../../../../styles/colors.scss';

.emphasis-container {
  width: 1250px;
  height: 300px;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  box-shadow: 0px 0px 8px 2px rgba(99, 99, 99, 0.2);
  border-radius: 10px;
  cursor: pointer;

  @media only screen and (max-width: 1250px) {
    display: none;
  }

  .image-container {
    width: 500px;
    height: 300px;
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;

    .image {
      width: 100%;
      height: 300px;
      object-fit: cover;
      -webkit-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;

      &.none {
        display: none;
      }

      &.flex {
        display: flex;
      }

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .description-container {
    width: 750px;
    height: 300px;
    color: $LP-bgColor;

    .line-clamp {
      -webkit-line-clamp: 10;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: normal;
      overflow: hidden;

      .title {
        font-size: 1.5rem;
        margin: 2rem 2rem 1rem 2rem;
      }

      .date {
        margin: 0.2rem 2rem;
        font-weight: bold;
        opacity: 0.6;
      }

      .description {
        margin: 1rem 2rem 0rem 2rem;
      }
    }
  }
}
